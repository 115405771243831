import React from "react"
import * as Icon from "react-feather"
import {faSignature} from "@fortawesome/free-solid-svg-icons/faSignature";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMoneyBillWave} from "@fortawesome/free-solid-svg-icons/faMoneyBillWave";
import {faChartLine} from "@fortawesome/free-solid-svg-icons/faChartLine";
import {
  faBell,
  faBuilding,
  faCalendarAlt,
  faDesktop, faDollarSign, faFile, faFunnelDollar, faHandHolding, faHome, faIdCard, faLightbulb, faList,
  faLock,
  faMagic, faMoneyBillAlt, faMoneyBillWaveAlt, faMoneyCheck,
  faPlaceOfWorship, faShoppingBag, faTools, faTrademark, faUserPlus, faUsers,
  faUserSecret
} from "@fortawesome/free-solid-svg-icons";

const navigationConfig = [
  {
    id: "resolucion_112",
    title: "Resolucion UIF 112/2021",
    type: "item",
    icon: <FontAwesomeIcon icon={faHome}/>,
    permissions: ["CL"],
    navLink: "/resolucionuif112"
  },

]

export default navigationConfig
