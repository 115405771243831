const initialState =  {
    beneficiarios:[],
}

const beneficiariosReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'setBeneficiarios':
            return {
                ...state,
                beneficiarios: action.payload
            }


        default:
            return state
    }
}

export default beneficiariosReducer;
