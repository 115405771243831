import axios from "axios";
import { getCookie } from "./helpers/cookies";

class Api {

    urlBase = () => {
        return process.env.REACT_APP_API_ENDPOINT;
    }


    headers = () => {
        return {
            'Authorization': 'Bearer ' + getCookie('api_token'),
        };
    }

    options = (method) => {
        return {
            method: method,
            headers: this.headers(),
        };

    }

    login = async (data) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json'
            },
        };

        let url = this.urlBase() + 'login';
        console.log(url);
        let response = await axios.post(url, data, requestOptions);
        return response.data;
    };

    prelogin = async (data) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
        };

        let url = this.urlBase() + 'prelogin';
        let response = await axios.post(url, data, requestOptions);
        return response.data;
    };

    getMyResolucion112 = async () => {

        let url = this.urlBase() + 'resolucion112/get-my';
        let response = await axios.get(url, this.options("GET"));
        return response.data;
    };


    storeResolucion112 = async (data) => {

        let url = this.urlBase() + 'resolucion112/store';
        console.log(url);
        let response = await axios.post(url, data, this.options("POST"));
        return response.data;
    };

    confirmResolucion112 = async () => {

        let url = this.urlBase() + 'resolucion112/confirm';
        console.log(url);
        let response = await axios.post(url, {}, this.options("POST"));
        return response.data;
    };

    validateResolucion112Detail = async (data) => {

        let url = this.urlBase() + 'resolucion112/validate-detail';
        console.log(url);
        let response = await axios.post(url, data, this.options("POST"));
        return response.data;
    };

    validateResolucion112Director = async (data) => {

        let url = this.urlBase() + 'resolucion112/validate-director';
        console.log(url);
        let response = await axios.post(url, data, this.options("POST"));
        return response.data;
    };


}

export default new Api;
