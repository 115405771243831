const initialState =  {
    propietarios:[]
}

const propietariosReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'setPropietarios':
            return {
                ...state,
                propietarios: action.payload
            }

        default:
            return state
    }
}

export default propietariosReducer;
