import { combineReducers } from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import navbar from "./navbar/Index"
import calenderReducer from "./calendar";
import novedadesReducer from "./novedades";
import beneficiariosReducer from "./beneficiarios";
import propietariosReducer from "./propietarios";

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  novedadesReducer: novedadesReducer,
  navbar: navbar,
  calendar: calenderReducer,
  beneficiariosReducer: beneficiariosReducer,
  propietariosReducer: propietariosReducer,

})

export default rootReducer
