import React from "react"
import  {
    FormGroup,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
} from "reactstrap"
import {connect} from 'react-redux';
import * as Icon from "react-feather"
import { history } from "../../../history"
import {setCookie, getCookie} from "../../../helpers/cookies";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Input
} from "reactstrap"
import {Flip, toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
import "../../../assets/scss/plugins/extensions/toastr.scss"
import API from './../../../api';

class NavbarUser extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            navbarSearch: false,
            suggestions: [],
            modal:false,
        }

        this.changePassword = this.changePassword.bind(this);
    }


    handleNavbarSearch = () => {
        this.setState({
            navbarSearch: !this.state.navbarSearch
        })
    }

    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }))
    }

    async changePassword(){
        let obj = {
            password: this.state.password,
        }
        /*
        API.changePassword(this.state.password).then((response)=>{
            this.setState({modal:false});
        })

         */
    }


    render() {
        const user = getCookie('user');
        return (
            <div>
                <ToastContainer />

                <ul className="nav navbar-nav navbar-nav-user float-right">
                    {user!=undefined &&
                    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
                        <DropdownToggle tag="a" className="nav-link dropdown-user-link">
                            <div className="user-nav d-sm-flex d-none">
                          <span className="user-name text-bold-600 text-uppercase text-primary">
                            {user.name}
                          </span>
                                <span className="user-status">{user.rol.descripcion}</span>
                            </div>
                        </DropdownToggle>

                        <DropdownMenu right>

                            <DropdownItem
                                tag="a"
                                href="#"
                                onClick={e => {
                                    setCookie('user',undefined)
                                    setCookie('api_token',undefined)
                                    setCookie('rol',undefined)
                                    history.push("/login")
                                }}>

                                <Icon.Power size={14} className="mr-50" />
                                <span className="align-middle">Cerrar Sesion</span>
                            </DropdownItem>

                        </DropdownMenu>



                    </UncontrolledDropdown>
                    }
                </ul>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        solicitudes: state.solicitudes
    }
}

const mapDispatchToProps = dispatch => {
}


export default connect(mapStateToProps, mapDispatchToProps)(NavbarUser);